.custom-toast {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}

.custom-toast .icon {
    margin-right: 10px;
}

.custom-toast .message {
    flex-grow: 1;
}

.custom-toast .close-button {
    cursor: pointer;
}

/* Outlined styles */
.custom-toast.outlined.error {
    border: 1px solid #e74c3c;
    color: #e74c3c;
}

.custom-toast.outlined.success {
    border: 1px solid #2ecc71;
    color: #2ecc71;
}

.custom-toast.outlined.warning {
    border: 1px solid #f39c12;
    color: #f39c12;
}

.custom-toast.outlined.info {
    border: 1px solid #3498db;
    color: #3498db;
}

/* Filled styles */
.custom-toast.filled.error {
    background-color: #e74c3c;
    color: #fff;
}

.custom-toast.filled.success {
    background-color: #2ecc71;
    color: #fff;
}

.custom-toast.filled.warning {
    background-color: #f39c12;
    color: #fff;
}

.custom-toast.filled.info {
    background-color: #3498db;
    color: #fff;
}

.Toastify__toast {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
}

.Toastify__toast-container {
    padding: 0;
    margin: 0;
    width: auto;
}

.Toastify__toast-body {
    margin: 0;
    padding: 0;
}

.Toastify__close-button {
    display: none;
}