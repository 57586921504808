@keyframes moon {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-60px);
    }
}
  
.image-moon {
    animation: moon 1.75s infinite alternate;
    animation-delay: 0.8s;
}

@keyframes numberPos {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-60px);
    }
}
  
.image-numberPos {
    animation: numberPos 1.75s infinite alternate;
    animation-delay: 0.8s;
}

@keyframes numberPre {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(60px);
    }
}
  
.image-numberPre {
    animation: numberPre 1.75s infinite alternate;
    animation-delay: 0.8s;
}

.circle {
    width: 100px;
    height: 100px;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    mix-blend-mode: difference;
    z-index: 6;
}

.highlight {
    color: #48A7D6;
    text-align: center;
    text-shadow: 0px 8px 32px rgba(0, 0, 0, 0.30);
    font-family: Inter;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 5px;
    cursor: default;
}

.highlight-letter {
    transition: color 0.5s ease;
}

.highlight-letter.highlighted {
    background: linear-gradient(270deg, #FD822A 0%, #AE6CF0 100%);
    background-clip: text;
    color: transparent;
    filter: blur(1.5px);
}
  
  