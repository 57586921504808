@keyframes spinner {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  .loading-spinner {
    width: 90px;
    height: 90px;
    animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    transform: rotateX(180deg);
    height: 350px;
  }